import styled from "styled-components"

import { colors, fonts, media } from "../../utils/tokens"

export const FldSet = styled.fieldset`
  border: none;
  margin: 0 0 4rem;
  padding: 0;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus {
    -webkit-text-fill-color: ${colors.portland};
    transition: background-color 5000s ease-in-out 0s;
  }
`

export const Legend = styled.legend`
  font-size: ${fonts.metaSM};
  font-weight: ${fonts.semi};
  line-height: ${fonts.metaLineHeight};
  letter-spacing: ${fonts.metaSpace};
  text-transform: uppercase;
  margin: 0 0 1.5rem;
  padding: 0;

  @media ${media.xl} {
    font-size: ${fonts.metaMD};
  }
`
