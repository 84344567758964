import React, { forwardRef } from "react"
import styled from "styled-components"

import { colors, fonts, media } from "../../utils/tokens"

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0 1.5rem;
`

export const FieldLabel = styled.label`
  margin: 0 0 .5rem;
  display: flex;
  align-self: flex-start;
  font-size: ${fonts.metaSM};
  font-weight: ${fonts.semi};
  line-height: ${fonts.metaLineHeight};
  letter-spacing: ${fonts.metaSpace};
  text-transform: uppercase;
  color: ${colors.portland};

  @media ${media.xl} {
    font-size: ${fonts.metaMD};
  }
`

export const Field = styled.input`
  margin: 0;
  padding: 0.875rem .5rem;
  font-size: ${fonts.pSM};
  font-weight: ${fonts.reg};
  line-height: ${fonts.pLineHeight};
  border-radius: 4px;
  background: ${colors.limestone12};
  color: ${colors.portland};
  border: ${props =>
    props.errors ? `2px solid ${colors.redrock}` : `2px solid transparent`};
  outline: none;

  :focus {
    border: 2px solid ${colors.portland};
  }
`

export const FieldInput = forwardRef(
  ({ children, label, type, value, errors, className, optional }, ref) => {
    return (
      <FieldContainer>
        <FieldLabel htmlFor={value}>
          {label}
          {optional ? ` (optional)` : null}
        </FieldLabel>
        <Field
          type={type}
          name={value}
          id={value}
          ref={ref}
          className={className}
          errors={errors}
        />
        {children}
      </FieldContainer>
    )
  }
)
