import { useRef, useState, useEffect } from "react"

export default function useCopy() {
  const [isCopied, setCopyText] = useState(
    "(Click email address and copy to clipboard.)"
  )
  const textAreaRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      setCopyText("(Click email address and copy to clipboard.)")
    }, 3000)
  })

  function copyTextToClipboard() {
    textAreaRef.current.select()
    document.execCommand("copy")
    setCopyText("(Copied email address to clipboard.)")
  }

  return {
    isCopied,
    copyTextToClipboard,
    textAreaRef,
  }
}
