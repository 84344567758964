import React from "react"
import styled from "styled-components"

import { H2 } from "./typography"
import { colors } from "../utils/tokens"

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  padding: 0 0 4rem;
  display: flex;
  align-self: flex-start;
  align-items: flex-end;
  background: ${colors.asphalt};
`

export const SubmittedNote = ({ label }) => (
  <Container>
    <H2>{label}</H2>
  </Container>
)
