import React, { useState } from "react"
import styled from "styled-components"
import useForm from "react-hook-form"

import { MetaPara } from "./typography"
import { FldSet, Legend } from "./inputs/inputs"
import { RadioInput } from "./inputs/radioInput"
import { FieldInput } from "./inputs/fieldInput"
import { TextareaInput } from "./inputs/textareaInput"
import { SubmittedNote } from "./submittedNote"
import { db } from "./firebase"

import { colors, fonts, media } from "../utils/tokens"

const Container = styled.form`
  margin: 0;
  padding: 0 0 6rem;
  display: ${props => (props.result ? `none` : `flex`)};
  flex-direction: column;
  position: relative;

  @media ${media.xl} {
    padding: 0 0 8rem;
  }
`

const ButtonSubmit = styled.button`
  padding: .875rem;
  width: 100%;
  display: inline-flex;
  align-self: flex-end;
  align-content: center;
  justify-content: center;
  font-size: ${fonts.metaSM};
  font-weight: ${fonts.semi};
  line-height: ${fonts.metaLineHeight};
  letter-spacing: ${fonts.metaSpace};
  text-transform: uppercase;
  border-radius: 4px;
  border: 2px solid transparent;
  color: ${colors.asphalt};
  background-color: ${colors.sand};

  :disabled {
    background-color: transparent;
    border: 2px solid ${colors.portland};
    color: ${colors.portland};
  }

  @media ${media.sm} {
    padding: .75rem 4rem;
    width: auto;
  }

  @media ${media.xl} {
    padding: 1rem 4rem;
    font-size: ${fonts.metaMD};
  }
`

const FormContainer = ({
  children,
  label,
  onSubmit,
  className,
  result,
  disabled,
}) => (
  <Container
    onSubmit={onSubmit}
    className={className}
    result={result}
    noValidate
  >
    {children}
    <ButtonSubmit type="submit" disabled={disabled}>
      {label}
    </ButtonSubmit>
  </Container>
)

export const ContactForm = () => {
  const { register, handleSubmit, errors, formState } = useForm({
    defaultValues: {
      type: "New business",
    },
    mode: "onBlur",
  })

  const [submitted, showResult] = useState("")
  const onSubmit = (data, e) => {
    db.collection("contacts").add({
      type: data.type,
      name: data.name,
      email: data.email,
      organization: data.organization,
      message: data.message,
    })
    // console.log(data)
    e.target.reset()
    showResult(`Thank you. We'll be in touch.`)
  }

  // console.log(errors)
  // console.log(formState.dirty)
  // console.log("isValid", formState.isValid)

  return (
    <>
      {submitted ? <SubmittedNote label={submitted} /> : null}
      <FormContainer
        label="send"
        onSubmit={handleSubmit(onSubmit)}
        result={submitted}
        disabled={!formState.dirty}
      >
        <FldSet>
          <Legend>Are you contacting us for</Legend>
          <RadioInput
            name="type"
            id="business"
            value="New business"
            ref={register({ required: true })}
          />
          <RadioInput
            name="type"
            id="inquiries"
            value="Inquiries"
            ref={register({ required: true })}
          />
          <RadioInput
            name="type"
            id="careers"
            value="Careers"
            ref={register({ required: true })}
          />
          <FieldInput
            type="text"
            value="name"
            label="your name"
            ref={register({
              required: `How should we address you?`,
              maxLength: 100,
            })}
            errors={!!errors.name}
          >
            {errors.name && (
              <MetaPara errors="true">{errors.name.message}</MetaPara>
            )}
          </FieldInput>
          <FieldInput
            type="email"
            value="email"
            label="Your email"
            ref={register({
              required: `We need your email address to get in touch with you.`,
              pattern: {
                value: /^\S+@\S+\.\S+$/i,
                message: `That email address looks a bit weird.`,
              },
            })}
            errors={!!errors.email}
          >
            {errors.email && (
              <MetaPara errors="true">{errors.email.message}</MetaPara>
            )}
          </FieldInput>
          <FieldInput
            type="text"
            value="organization"
            label="your brand/organization"
            optional={true}
            ref={register({ maxLength: 100 })}
          />
          <TextareaInput
            rows="5"
            value="message"
            label="Your message"
            ref={register({
              required: `How can we be at your service? Tell us the details.`,
            })}
            errors={!!errors.message}
          >
            {errors.message && (
              <MetaPara errors="true">{errors.message.message}</MetaPara>
            )}
          </TextareaInput>
        </FldSet>
      </FormContainer>
    </>
  )
}
