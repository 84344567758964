import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  HeroSection,
  SmallSection,
  LargeSection,
} from "../components/containers"
import { H1, H4, P, MetaPara } from "../components/typography"
import { FlDivider } from "../components/dividers"
import { CopyButton, CopyInput } from "../components/inputs/copyInput"
import { ContactForm } from "../components/contactForm"

import useCopy from "../hooks/copy-hooks"

const ContactPage = () => {
  const { isCopied, copyTextToClipboard, textAreaRef } = useCopy()
  const organizationEmail = `hello@concrezan.com`

  return (
    <Layout noContact="true">
      <SEO
        title="Contact"
        description="Find contact information for engaging with us and career opportunities with our design studio. Concrezan is an independent brand experience and service design consultancy."
      />
      <HeroSection>
        <H4>Contact Us</H4>
        <H1>Let’s work together.</H1>
        <P>
          If you want to transform your business or get to know more about what
          we're doing, you're in the right place.
        </P>
      </HeroSection>
      <FlDivider />
      <SmallSection left="true">
        <P>Fill in the form, or, if you prefer, send us an email at</P>
        <P>
          <CopyButton onClick={copyTextToClipboard}>
            {organizationEmail}
          </CopyButton>
        </P>
        <MetaPara>{isCopied}</MetaPara>
        <CopyInput ref={textAreaRef} defaultValue={organizationEmail} />
      </SmallSection>
      <LargeSection>
        <ContactForm />
      </LargeSection>
    </Layout>
  )
}

export default ContactPage
