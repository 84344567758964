import React, { forwardRef } from "react"
import styled from "styled-components"

import { colors, fonts, media } from "../../utils/tokens"

const CopyForm = styled.form`
  padding: 0;
  margin: 0;
  opacity: 0;
  height: 0;

  textarea {
    color: transparent;
    background: transparent;
    height: 1px;
    width: 1px;
    resize: none;
  }
`

export const CopyButton = styled.button`
  padding: 0 0 4px;
  margin: 0;
  position: relative;
  display: inline-flex;
  align-self: flex-start;
  font-size: inherit;
  font-weight: ${fonts.semi};
  line-height: inherit;
  color: inherit;

  &:hover {
    color: ${colors.limestone};
  }

  &:focus {
    outline: 1px dotted ${colors.sand};
  }

  &::before,
  &::after {
    position: absolute;
    height: 2px;
    bottom: 0;
    content: "";
  }

  &::before {
    left: 0;
    right: 0;
    background-color: ${colors.portland};
    transition: 0.4s ease-in-out;
  }

  &:hover::before,
  &:focus::before {
    left: 100%;
    right: 0;
    transition: 0.4s ease-in-out;
    transform: scaleX;
  }

  &::after {
    left: 0;
    right: 100%;
    background-color: ${colors.limestone};
  }

  &:hover::after,
  &:focus::after {
    left: 0;
    right: 0;
    transition: 0.4s ease-in-out 0.4s;
    transform: scaleX;
  }

  @media ${media.xl} {

    &::before,
    &::after {
      height: 3px;
    }
  }
`

export const CopyInput = forwardRef(({ defaultValue, className }, ref) => (
  <CopyForm aria-hidden="true" className={className}>
    <textarea ref={ref} defaultValue={defaultValue} readOnly />
  </CopyForm>
))
