import React, { useCallback, useEffect, useRef, forwardRef } from "react"
import styled from "styled-components"

import { colors, fonts, media } from "../../utils/tokens"

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0 1.5rem;
`

export const FieldLabel = styled.label`
  margin: 0 0 .5rem;
  display: flex;
  align-self: flex-start;
  font-size: ${fonts.metaSM};
  font-weight: ${fonts.semi};
  line-height: ${fonts.metaLineHeight};
  letter-spacing: ${fonts.metaSpace};
  text-transform: uppercase;
  color: ${colors.portland};

  @media ${media.xl} {
    font-size: ${fonts.metaMD};
  }
`

const Message = styled.textarea`
  margin: 0;
  padding: 0.875rem .5rem;
  font-size: ${fonts.pSM};
  font-weight: ${fonts.reg};
  line-height: ${fonts.pLineHeight};
  border-radius: 4px;
  background: ${colors.limestone12};
  color: ${colors.portland};
  border: ${props =>
    props.errors ? `2px solid ${colors.redrock}` : `2px solid transparent`};
  outline: none;
  resize: none;
  overflow: hidden;

  :focus {
    border: 2px solid ${colors.portland};
  }
`

const getHeight = (rows, el) => {
  const {
    borderBottomWidth,
    borderTopWidth,
    lineHeight,
    paddingBottom,
    paddingTop,
  } = window.getComputedStyle(el)

  const rowHeight =
    rows == null
      ? 0
      : parseFloat(lineHeight) * parseInt(rows, 10) +
        parseFloat(borderBottomWidth) +
        parseFloat(borderTopWidth) +
        parseFloat(paddingBottom) +
        parseFloat(paddingTop)

  const scrollHeight =
    el.scrollHeight + parseFloat(borderBottomWidth) + parseFloat(borderTopWidth)

  return Math.max(rowHeight, scrollHeight)
}

const resize = (rows, el) => {
  if (el) {
    el.style.height = "0"
    el.style.height = `${getHeight(rows, el)}px`
  }
}

function useCombinedRefs(...refs) {
  const targetRef = React.useRef()

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return

      if (typeof ref === "function") {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

export const TextareaInput = forwardRef(
  ({ children, label, value, errors, className, ...props }, ref) => {
    const innerRef = useRef(null)
    const combinedRef = useCombinedRefs(ref, innerRef)

    useEffect(() => {
      resize(props.rows, combinedRef.current)
    }, [props.rows, props.value, combinedRef])

    const handleInput = useCallback(
      e => {
        props.onChange(e)
        resize(props.rows, combinedRef.current)
      },
      [props, combinedRef]
    )

    return (
      <FieldContainer>
        <FieldLabel htmlFor={value}>{label}</FieldLabel>
        <Message
          {...props}
          name={value}
          id={value}
          onChange={handleInput}
          className={className}
          errors={errors}
          ref={combinedRef}
        />
        {children}
      </FieldContainer>
    )
  }
)

TextareaInput.defaultProps = {
  onChange: Function.prototype,
}
