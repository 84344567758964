import React, { forwardRef } from "react"
import styled from "styled-components"

import { colors, fonts, media } from "../../utils/tokens"

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 1.5rem;
`

export const RadioLabel = styled.label`
  font-size: ${fonts.pSM};
  font-weight: ${fonts.reg};
  line-height: ${fonts.pLineHeight};
  color: ${colors.limestone};

  @media ${media.xl} {
    font-size: ${fonts.pMD};
  }
`

export const Radio = styled.input`
  height: 1rem;
  width: 1rem;
  margin: 0 .5rem 0 0;
  border: 1px solid ${colors.limestone};
  appearance: none;
  border-radius: 50%;
  transition: 0.4s all ease-in-out;

  :focus {
    outline: 1px solid ${colors.sand};
  }

  :checked {
    background: ${colors.portland};
    border: none;

    + ${RadioLabel} {
      color: ${colors.portland};
    }
  }
`

export const RadioInput = forwardRef(({ name, id, value }, ref) => {
  return (
    <RadioContainer>
      <Radio type="radio" name={name} id={id} value={value} ref={ref} />
      <RadioLabel htmlFor={id}>{value}</RadioLabel>
    </RadioContainer>
  )
})
